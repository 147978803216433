import React from "react";

import Header from "../components/header";
import SEO from "../components/seo";

function MessageSent() {
  return (
    <>
      <SEO title="Agroalpha: Mensagem Enviada" />
      <Header />
      <div className="h-screen flex justify-center items-center bg-verde">
        <span className="text-2xl font-medium inline-block text-white">
          Obrigado por enviar a sua mensagem!
        </span>
      </div>
    </>
  );
}

export default MessageSent;
